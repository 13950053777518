// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: 'dev',
  apiUrls: {
    // api: 'http://localhost:4006'
    api: 'https://www.gtcleasing.com'
  },
  firebase: {
    apiKey: "AIzaSyA1F2_JskSD4O4-At9DKA1nZm_7ATwLCog",
    authDomain: "sw-leasing.firebaseapp.com",
    projectId: "sw-leasing",
    storageBucket: "sw-leasing.appspot.com",
    messagingSenderId: "496310025805",
    appId: "1:496310025805:web:40ccede7755c7e63896604",
    measurementId: "G-6P3NX63XMQ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
